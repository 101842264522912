/*
  If the number of TAB files has changed, the following variable is required.
  And it must be defined before `@import`.
*/
$tab-count: 5; // plus 1 for home tab

@import "jekyll-theme-chirpy";

/* append your custom style below */
.reversefootnote {
  @at-root a#{&} {
    font-size: 0.6rem;
    position: relative !important;
    line-height: 1;
    padding-top: 0.5em;
    margin-left: 0.5em;
    border-bottom-style: none !important;
  }
}


html:not([mode]),html[mode=dark] .gt-container .gt-svg svg {
  fill: #ffffff !important;
}

.gt-container a {
    color: #2a408e !important;
}

html:not([mode]),html[mode=dark] .gt-container a {
    color: rgb(138, 180, 248) !important;
}

.gt-container a:hover {
  color: #d2603a !important;
  border-color: #d2603a !important;
}

.gt-container .gt-spinner::before {
  border-color: #2a408e !important;
  border-top-color: #2a408e !important;
}

.gt-container .gt-btn {
  background-color: #2a408e !important;
  border-color: #2a408e !important;
  color: #ffffff !important;
}

.gt-container .gt-btn-login:hover {
  background-color: #d2603a !important;
  border-color: #d2603a !important;
}

.gt-container .gt-btn-preview {
  background-color: #ffffff !important;
  color: #2a408e !important;
}

.gt-container .gt-btn-preview:hover {
  background-color: #ffffff !important;
  border-color: #d2603a !important;
}

.gt-container .gt-btn-preview:hover .gt-btn-text {
  color: #d2603a !important;
}

.gt-container .gt-btn-public:hover {
  background-color: #d2603a !important;
  border-color: #d2603a !important;
}

.gt-container .gt-link:hover {
  border-bottom-color: #d2603a !important;
}

.gt-container .gt-header-controls-tip svg {
  fill: #2a408e !important;
}

html:not([mode]),html[mode=dark] .gt-container .gt-header-controls-tip svg {
  fill: rgb(138, 180, 248) !important;
}

.gt-container .gt-header-controls-tip:hover {
  color: #d2603a !important;
}

.gt-container .gt-header-controls-tip:hover svg {
  fill: #d2603a !important;
}

html:not([mode]),html[mode=dark] .gt-container .gt-header-controls-tip:hover svg {
  fill: #d2603a !important;
}

.gt-container .gt-popup .gt-action.is--active:before {
  background: #2a408e !important;
}

.gt-container .gt-header-controls-tip {
  color: #2a408e !important;
}

.gt-container .gt-comment-username {
  color: #2a408e !important;
}

img {
	border-radius: 8px;
}